@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border: solid 1px var(--colorGrey100);
  padding: 20px;
  border-radius: var(--borderRadiusMedium);
  text-align: center;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.ImgWrapper {
  width: 96px;
  height: 96px;
  border-radius: 100px;
  margin: 0 auto 24px;
  position: relative;
  & .activeStatus {
    z-index: 1;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background-color: var(--colorSuccess);
    border: solid 2px var(--colorWhite);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  width: 100%;
  height: 100%;
  border-radius: 100px;
  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: 100px;
  width: 100%;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  & p {
    font-size: 14px;
    line-height: 120%;
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrey400);
    margin: 0 0 8px 0;
    padding: 0;
    & > span {
      color: var(--colorGrey800);
      display: inline-flex;
      align-items: center;
      & > svg {
        margin-right: 6px;
        min-width: 12px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.title {
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey800);
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 16px;
  line-height: 120%;
}

.authorInfo {
  font-size: 14px;
  line-height: 120%;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey600);
  margin: 0 0 8px 0;
  padding: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.viewProfile {
  composes: buttonPrimary from global;
  padding: 14px 20px;
  min-height: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-left: 10px;
  }
}
.manufacturers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping to the next line if there are too many pills */
  gap: 8px; /* Space between pills */
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.manufacturerPill {
  background-color: #ffede8;
  text-transform: capitalize;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: #000;
  display: inline-block;
  white-space: nowrap; /* Prevent text wrapping inside the pill */
}
.otherDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  border-top: solid 1px var(--colorGrey100);
  padding: 20px 0;
  & .infoBlock {
    text-align: left;
    & > h4 {
      font-weight: var(--fontWeightMedium);
      color: var(--colorGrey800);
      margin: 0 0 8px 0;
      padding: 0;
      font-size: 14px;
      line-height: 120%;
    }
    & > p {
      font-size: 14px;
      line-height: 120%;
      font-weight: var(--fontWeightRegular);
      color: var(--colorGrey600);
      margin: 0 0 8px 0;
      padding: 0;
    }
  }
}
