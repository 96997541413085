.mediaUploadSection {
  margin-bottom: 20px;
}

.dropArea {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.uploadedFiles {
  margin-top: 20px;

  & .filethumb {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusLarge);
    border: solid 1px var(--colorGrey100);
    padding: 20px;
    transition: var(--transitionStyle);
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    &:hover {
      border: solid 1px var(--colorGrey200);
      box-shadow: var(--boxShadow);
    }
    & .filethumbIcon {
    }
    & .filethumbInfoWrapper {
      width: 100%;
      /* width: calc(100% - 40px);
      padding-left: 10px; */
      position: relative;
      & .filethumbInfo {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & .leftInfo {
          & > h2 {
            margin: 0 0 8px 0;
            padding: 0;
            font-size: 16px;
            line-height: 110%;
            color: var(--colorGrey800);
          }
          & > p {
            font-size: 14px;
            line-height: 110%;
            color: var(--colorGrey700);
            padding: 0;
            margin: 0;
          }
        }
        & .rightInfo {
          & > svg {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
          }
          & .delete {
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-color: var(--marketplaceColor);
            color: var(--colorWhite);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            & > svg {
              width: 16px;
              height: 16px;
              & path {
                fill: var(--colorWhite);
              }
            }
          }
        }
      }
      & .progressBarContainer {
        width: 100%;
        height: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
        gap: 10px;
        & .progressBar {
          border-radius: 100px;
          height: 100%;
          background-color: var(--marketplaceColor);
          position: relative;
        }
      }
    }
  }
}

.videoUploaderSec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (--viewportSmall) {
    flex-direction: row;
    justify-content: space-between;
  }
  & .vdoDropArea {
    border: solid 1px var(--colorGrey100);
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusLarge);
    padding: 20px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: var(--transitionStyle);
    width: 100%;
    @media (--viewportSmall) {
      width: calc(100% - 80px);
    }
    &:hover {
      transition: var(--transitionStyle);
      border: solid 1px var(--colorGrey200);
      box-shadow: var(--boxShadow);
      cursor: pointer;
    }
    & .uploadCloud {
      background-color: var(--colorWhite);
      border-radius: var(--borderRadiusMedium);
      border: solid 1px var(--colorGrey100);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--boxShadow);
      width: 40px;
      height: 40px;
    }
    & > p {
      margin: 12px 0 0 0;
      padding: 0;
      font-size: 14px;
      line-height: 150%;
      color: var(--colorGrey600);
      & > span {
        font-size: 15px;
        color: var(--marketplaceColor);
        font-weight: var(--fontWeightSemiBold);
      }
    }
  }
}
.error {
  font-size: small;
  color: red;
}
