@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  margin: 0;
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: auto;
  width: auto;
  /* Mobile topbar has maximum of 100vw - 132px space for logo */
  object-fit: contain;
  object-position: center;
}

.logo24 {
  max-height: 24px;
}
.logo36 {
  max-height: 36px;
}
.logo48 {
  max-height: 48px;
}
