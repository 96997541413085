@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.listingTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}

.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

.unitTypeHidden {
  display: none;
}

.submitButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & .formFld {
    width: calc(100% / 1);
    margin-bottom: 24px;

    @media (--viewportMedium) {
      width: calc(100% / 2 - 12px);
    }

    & > div {
      margin-bottom: 0;
    }

    &.formCheckFld {
      margin-bottom: 14px;
    }
  }

  &.fullWidth {
    flex-direction: column;

    & .formFld {
      width: 100%;
      margin-right: 0;

      & > h2 {
        font-size: 24px;
      }
    }
  }
}

.locationAutocompleteInput {
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.skillSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.skillLabel {
  font-size: 13px;
  display: block;
}

.skillContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillButton {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  padding: 4px 15px;
  font-size: 14px;
  border: 1px solid var(--colorGrey200);
  border-radius: 15px;
  background-color: var(--colorWhite);
  color: var(--colorGrey500);
  cursor: pointer;
  line-height: 120%;
  height: auto;
  min-height: auto;
  transition: background-color 0.3s;
  width: auto;

  &.selected,
  &:focus {
    background-color: #007bff;
    color: white;
    border-color: #007bff;

    &:hover {
      background-color: #007bff;
      color: white;
      border-color: #007bff;
    }
  }

  &:hover {
    background-color: var(--colorWhite);
    border: 1px solid var(--colorGrey200);
    color: var(--colorGrey500);
  }
}

.seeMoreButton {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  margin: 10px 0 20px;
  text-decoration: underline;
}

.experienceSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.experienceLevel {
  margin-top: 12px;
  & .expLabelText {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 120%;

    & > p {
      padding: 0;
    }
  }

  & > span {
    & > label {
      padding: 0;
      margin: 0;

      & > span {
        line-height: 100%;

        &:first-child {
          align-self: flex-start;
          margin-top: 2px;
          height: 16px;
          width: 16px;
        }

        &:last-child {
          padding: 0;
        }
      }
    }
  }
}

.description {
  color: #666;
  font-size: 14px;
  margin: 0;
}

.skillLevelSection {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  & .formFld {
    width: calc(100% / 1);
    margin-bottom: 24px;

    @media (--viewportMedium) {
      width: calc(100% / 2 - 12px);
    }

    &:nth-child(odd) {
      @media (--viewportMedium) {
        margin-right: 12px;
      }
    }

    & > div {
      margin-bottom: 0;
    }

    &.formCheckFld {
      margin-bottom: 14px;
    }
  }
}

.editListingBtns {
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: flex-start;
  gap: 16px;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }

  & .submitButton {
    margin: 0;
    display: inline-block;
    width: 100%;

    @media (--viewportSmall) {
      max-width: 200px;
    }
  }
}

.priceSection {
  width: 100%;

  & .sectionLabel {
  }

  & .priceRadioButtonRow {
    margin-bottom: 24px;
    display: flex;
    gap: 16px;

    & > span {
      width: calc(100% / 2);

      @media (--viewportSmall) {
        width: calc(100% / 3);
      }

      & > input:checked + label {
        border-color: var(--colorSuccessDark);
      }

      & > label {
        cursor: pointer;
        margin: 0;
        border: solid 2px var(--colorGrey200);
        border-radius: var(--borderRadiusLarge);
        padding: 14px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (--viewportMedium) {
          padding: 24px;
        }

        & > span {
          font-size: 14px;
          line-height: 100%;

          &:first-child {
            height: 16px;
            align-self: flex-start;
            width: 16px;
            margin: 0;
            order: 2;
          }

          &:last-child {
            padding: 0;
            order: 1;
          }
        }
      }
    }
  }
}

.priceRangeSection {
  margin-bottom: 20px;
}

.fixedPriceSection {
}

.otherDetails,
.imagesField {
  margin: 0;
  background-color: var(--colorWhite);
  border: solid 1px var(--borderLightColor);
  padding: 20px 0px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.photosFormDropZoneContainer {
  background-color: var(--bgLightColor);
  border-color: var(--borderLightColor);
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  transition: var(--transitionStyleButton);
  position: relative;

  &:hover {
    background-color: var(--colorWhite);
  }

  & :global(.dropzone) {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 100%;
  }
}

.imageUploadWrapper {
}

.sectionTitle {
  font-family: var(--fontWeightSemiBold);
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 3px;
  padding-bottom: 13px;
  font-size: 13px !important;
}

.fixedPriceSection {
  margin-bottom: 14px;
}
.keyResponsibilitiesSection {
  width: 100%;
}
.responsibilityField {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  & .textField {
    flex-grow: 1;
  }
  & .removeButton {
    border: 1px solid #d8dce6;
    padding: 6px 16px;
    border-radius: var(--borderRadiusLarge);
    font-size: 14px;
    line-height: 120%;
    transition: var(--transitionStyle);
    height: 45px;
    &:hover {
      transition: var(--transitionStyle);
      border: solid 1px var(--colorGrey800);
      cursor: pointer;
    }
  }
}
.addButton {
  border: solid 1px var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  padding: 10px 16px;
  border-radius: var(--borderRadiusLarge);
  font-size: 14px;
  line-height: 120%;
  transition: var(--transitionStyle);
  margin-bottom: 24px;
  &:hover {
    transition: var(--transitionStyle);
    border: solid 1px var(--marketplaceColor);
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    cursor: pointer;
  }
  & > svg {
    width: 13px;
    height: 13px;
    margin-right: 10px;
    & path {
      stroke: var(--colorWhite);
    }
  }
}
