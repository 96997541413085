@import '../../../../../styles/customMediaQueries.css';

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 0;
  margin-top: 30px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 24px;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: calc(50% - 12px);

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: var(--colorGrey100);
}

.fieldDatePicker {
  flex-grow: 1;
}

.fieldDateInput {
  width: calc(100% - 24px);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}

.fieldDatePopup {
  position: absolute;
  z-index: 1;
  width: calc(200% + 24px);

  @media (min-width: 320px) {
    width: calc(200% - 24px);
    margin: 0 24px;
  }
  @media (--viewportMedium) {
    width: calc(200% + 24px);
    margin: 0;
  }
}

.fieldSelect {
  flex-grow: 1;
  margin-left: 0;
  margin-right: 24px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 12px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 12px;
      bottom: 21px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 12px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 12px;
      bottom: 21px;
    }
  }
}

.select {
  position: relative;
  padding-left: 30px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;

  @media (--viewportMedium) {
    padding-left: 32px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}
