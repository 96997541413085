@import '../../../styles/customMediaQueries.css';

.panel {
  padding: 24px;
  background-color: var(--colorWhite);
  max-width: 800px;
  box-shadow: var(--boxShadow);
  width: 100%;
  margin: 24px auto;
  @media (--viewportMedium) {
    margin: 40px auto;
  }
}
