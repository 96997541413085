@import '../../../../styles/customMediaQueries.css';
.reorderWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
  & .reorderWrapper {
    display: flex;
    margin: 0;
    position: relative;
  }

  & .reorderImages {
    position: relative;
    width: 100%;
    & img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  & .removeImage {
    z-index: 1;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #0000006e;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #0000008c;
    }
    & > svg {
      width: 15px;
      height: 15px;
      fill: none;
      & path {
        fill: var(--colorWhite);
      }
    }
  }
}
.addImageWrapper {
  float: left;
  position: relative;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey200);
  }
}

.chooseImageText {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > svg {
    width: 24px;
    height: 24px;
    fill: none;
    & path {
      fill: var(--textColor);
    }
  }
}

.chooseImage {
  line-height: 140%;
  font-size: 11px;
  color: var(--colorGrey500);
  font-weight: var(--fontWeightSemiBold);
  margin: 10px 0;
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey400);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.reorderWrapperItem {
}

.photosFormDropZoneContainer {
  background-color: var(--bgLightColor);
  border-color: var(--borderLightColor);
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  transition: var(--transitionStyleButton);
  position: relative;
  &:hover {
    background-color: var(--colorWhite);
  }
  & :global(.dropzone) {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 100%;
  }
}
