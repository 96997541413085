@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 20px;
    line-height: 120%;
    color: var(--colorGrey900);
    padding: 0;
    margin: 0 0 20px 0;
  }
}

.form {
  flex-grow: 1;
}
